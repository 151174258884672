import { ConstructionIcon, EnterpriseIcon, HrIcon, ItIcon, MarketingIcon, MediaIcon, OperationsIcon, PmoIcon, ProductIcon, RetailIcon, RndIcon, SalesIcon, SmbIcon } from "./icons/index.js";
import { CONSTRUCTION_MINI_SITE_LINK, CRM_MINI_SITE_LINK, ENTERPRISE_PAGE_LINK, HR_USE_CASES_LINK, MEDIA_PAGE_LINK, OPERATIONS_CLUSTER_LINK, RETAIL_PAGE_LINK, SERVICE_MINI_SITE_LINK, SMB_PAGE_LINK, SOFTWARE_MINI_SITE_LINK, WORK_MANAGEMENT_FOR_MARKETING_MINI_SITE_LINK, WORK_MANAGEMENT_FOR_PMO_MINI_SITE_LINK } from "constants/links";
export const COMPANY_SIZE_LINKS = [
    {
        Icon: EnterpriseIcon,
        title: "header.tabs.solution.enterprise.title",
        link: ENTERPRISE_PAGE_LINK,
        description: "header.tabs.solution.enterprise.description",
        text: "header.tabs.solution.enterprise"
    },
    {
        Icon: SmbIcon,
        title: "header.tabs.solution.smb.title",
        link: SMB_PAGE_LINK,
        description: "header.tabs.solution.smb.description"
    }
];
export const TEAMS_LINKS = [
    {
        Icon: MarketingIcon,
        text: "header.tabs.solution.marketing",
        link: WORK_MANAGEMENT_FOR_MARKETING_MINI_SITE_LINK
    },
    {
        Icon: PmoIcon,
        text: "header.tabs.solution.pmo",
        link: WORK_MANAGEMENT_FOR_PMO_MINI_SITE_LINK
    },
    {
        Icon: SalesIcon,
        text: "header.tabs.solution.sales",
        link: CRM_MINI_SITE_LINK
    },
    {
        Icon: OperationsIcon,
        text: "header.tabs.solution.operations",
        link: OPERATIONS_CLUSTER_LINK
    },
    {
        Icon: ProductIcon,
        text: "header.tabs.solution.product",
        link: SOFTWARE_MINI_SITE_LINK
    },
    {
        Icon: ItIcon,
        text: "header.tabs.solution.it",
        link: SERVICE_MINI_SITE_LINK
    },
    {
        Icon: RndIcon,
        text: "header.tabs.solution.rnd",
        link: SOFTWARE_MINI_SITE_LINK
    },
    {
        Icon: HrIcon,
        text: "header.tabs.solution.hr",
        link: HR_USE_CASES_LINK
    }
];
export const INDUSTRIES_LINKS = [
    {
        Icon: RetailIcon,
        text: "header.tabs.solution.retail",
        link: RETAIL_PAGE_LINK
    },
    {
        Icon: MediaIcon,
        text: "header.tabs.solution.media",
        link: MEDIA_PAGE_LINK
    },
    {
        Icon: ConstructionIcon,
        text: "header.tabs.solution.construction",
        link: CONSTRUCTION_MINI_SITE_LINK
    }
];
